<template>
    <div class="c-mobileMenu">
        <a href="#" class="toggle" @click.prevent="toggleMenu" :class="mobileMenuOpen ? 'active' : ''">
            <span></span>
            <span></span>
            <span></span>
        </a>

        <a href="/search" class="search"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg></a>

        <nav v-if="mobileMenuOpen">
            <div class="logo">
                <a href="/"><img src="/assets/images/central/central-logo.svg" alt="CVCA Central Logo" height="60"></a>
            </div>

            <ul class="primary">
                <li><a class="menu" href="/">Home</a></li>
                <li><a class="menu" href="/all">Recent</a></li>
                <li><a class="menu" href="/community">Community</a></li>
                <li>
                    <a href="#" class="menu" @click.prevent="showSub('topics')">Topics</a>
                    <a href="#" class="innerToggle" :class="{ open: openMenu == 'topics' }" @click.prevent="showSub('topics')"><span></span><span></span></a>
                    <ul class="second" v-if="openMenu == 'topics'">
                        <!-- <li><a href="/all/trending">Trending</a></li> -->
                        <li><a href="/all/opinion">Opinion</a></li>
                        <li><a href="/all/data-analysis">Data &amp; Analysis</a></li>
                        <li><a href="/all/policy-advocacy">Policy &amp; Advocacy</a></li>
                        <li><a href="/all/cvca-member-profiles">CVCA Member Profiles</a></li>
                    </ul>
                </li>

                <li>
                    <a href="#" class="menu" @click.prevent="showSub('filter')">Filter by ...</a>
                    <a href="#" class="innerToggle" :class="{ open: openMenu == 'filter' }" @click.prevent="showSub('filter')"><span></span><span></span></a>
                    <ul class="second" v-if="openMenu == 'filter'">
                        <li><a href="/">All News</a></li>
                        <li><a href="/vc">Venture Capital Only</a></li>
                        <li><a href="/pe">Private Equity Only</a></li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                mobileMenuOpen: false,
                openMenu: 0
            }
        },

        props: {
            pages: {
                type: Array,
                default: []
            },
        },

        computed: {
            pageTree() {
                return this.createTree( this.pages );
            }
        },

        methods : {
            createTree(array) {
                let a = array
                let result = []
                let levels = [{ children: result }]

                a.forEach(function (o) {
                    let thisLevel = o.level - 1
                    levels[thisLevel].children = levels[thisLevel].children || [];
                    levels[thisLevel].children.push(levels[thisLevel + 1] = o);
                });

                return result;
            },

            toggleMenu( status = '' ) {
                if( status  == 'off' ) {
                    this.mobileMenuOpen = false;
                } else {
                    this.mobileMenuOpen = !this.mobileMenuOpen;
                }

                if( this.mobileMenuOpen ) {
                    document.querySelector('body').classList.add('has-mobile-menu');
                } else {
                    document.querySelector('body').classList.remove('has-mobile-menu');
                }
            },

            menuOn(menu) {
                this.showMenu[menu] = true;
            },

            menuOff(menu) {
                if( menu == 'all' ) {
                    this.toggleMenu( 'off' )
                } else {
                    this.showMenu[menu]  = false;
                }
            },

            showSub( pageid ) {
                this.openMenu = ( this.openMenu == pageid ) ? 0 : pageid
            }
        }
   }
</script>

<style lang="scss" scoped>

    @keyframes menuIn {
        from {opacity: 0;}
        to {opacity: 1;}
    }

    .c-mobileMenu {
        position: relative;
        z-index: 100000;

        nav {
            opacity: 0;
            max-height: 0px;
            overflow: hidden;
            z-index: 1000;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 0.5s ease-in-out;
            background: var(--blue);
            animation-name: menuIn;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;

            @include drawer-menu {
                max-height: 100vh;
            }

            .logo {
                padding-left: 20px;
                padding-top: 22px;
                margin-bottom: 10px;
                
                img {
                    max-width: 50vw;
                    max-height: 40px;
                }

                @include tiny {
                    padding-left: 10px;
                }
            }

            ul.primary {
                margin: 0;
                padding: 15px 15px;
                list-style: none;
                border-bottom: 1px solid var(--blue);
                display: flex;
                flex-direction: column;

                &.utility {
                    border-bottom: none;
                }

                @include tiny {
                    padding: 8px 0;
                }

                li {
                    margin: 0;
                    padding: 0;
                    position: relative;
                    max-width: 330px;

                    @include mobile {
                        max-width: 280px;
                    }

                    @include tiny {
                        max-width: 240px;
                    }

                    a.menu {
                        display: block;
                        padding: 10px;
                        font-size: 18px;
                        color: #fff;
                        text-decoration: none;
                        margin-bottom: 5px;
                        position: relative;
                        font-family: var(--text);
                        font-weight: 700;
                        text-transform: uppercase;

                        &.member {
                            background: var(--red);
                            color: #fff;
                        }

                        @include tiny {
                            padding: 8px 10px;
                            font-size: 16px;
                            margin-bottom: 6px;
                        }

                        &:hover, &:active {
                            background: var(--yellow);
                            color: var(--blue);
                        }

                        .chevron {
                            position: absolute;
                            right: 20px;
                            top: 10px;
                            font-size: 26px;
                        }
                    }

                    a.innerToggle {
                        position: absolute;
                        top     : 10px;
                        right   : -45px;
                        height  : 16px;
                        width   : 16px;
                        padding : 0;

                        @include tiny {
                            top: 5px;
                        }

                        &.open {
                            span {
                                &:nth-child(1) {
                                    opacity: 0;
                                }

                                &:nth-child(2) {
                                    transform  : rotate(-180deg);
                                    top        : 50%;
                                    left       : 0;
                                    margin-top : -2px;
                                }
                            }
                        }

                        span {
                            height          : 4px;
                            width           : 16px;
                            position        : absolute;
                            background-color: #fff;
                            transition      : all 0.3s ease-in-out;
                            transform-origin: 50% 50%;

                            &:nth-child(1) {
                                left            : 0;
                                top             : 50%;
                                margin-top      : -2px;
                            }

                            &:nth-child(2) {
                                transform  : rotate(90deg);
                                top        : 50%;
                                left       : 0;
                                margin-top : -2px;
                            }
                        }
                    }
                }
            }

            ul.second {
                margin: 0;
                padding: 0 0 10px 0;
                list-style: none;
                z-index: 1000;
                animation-name: menuIn;
                animation-duration: 0.5s;
                animation-fill-mode: forwards;
                display: flex;
                flex-direction: column;

                li {
                    position: relative;
                    margin: 0;
                    padding: 0 0 0 20px;

                    @include tiny {
                        padding-left: 12px;
                    }

                    a {
                        display: block;
                        padding: 10px;
                        font-size: 16px;
                        color: #fff;
                        text-decoration: none;
                        font-weight: bold;

                        @include tiny {
                            padding: 8px 0 8px 10px;
                            font-size: 15px;
                        }

                        &:hover {
                            color: var(--yellow);
                        }
                    }
                }
            }
        }

        .search {
            display: none;
            z-index: 1001;

            @include drawer-menu {
                top       : 22px;
                display   : block;
                position  : fixed;
                right     : 80px;
                height    : 24px;
                width     : 30px;
            }

            @include tiny {
                right: 60px;
            }
        }

        .toggle {
            display : none;
            z-index: 1001;

            @include drawer-menu {
                top       : 25px;
                display   : block;
                position  : fixed;
                right     : 20px;
                height    : 24px;
                width     : 30px;
            }

            @include tiny {
                right: 10px;
            }

            span {
                height          : 4px;
                width           : 30px;
                position        : absolute;
                left            : 0;
                top             : 0;
                background-color: #ffffff;
                transition      : all 0.3s ease-in-out;

                &:nth-child(1) {
                    body.has-mobile-menu & {
                        background-color: transparent;
                    }
                }

                &:nth-child(2) {
                    top: calc( 50% - 2px );

                    body.has-mobile-menu & {
                        top   : 10px;
                        transform: rotate(-45deg);
                    }
                }

                &:nth-child(3) {
                    bottom: 0;
                    top   : auto;

                    body.has-mobile-menu & {
                        transform: rotate(45deg);
                        bottom: auto;
                        top   : 10px;
                    }
                }
            }
        }
    }
</style>
