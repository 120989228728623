var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-lightbox",
      class: { active: _vm.image.url != "" },
      attrs: { tabindex: _vm.open ? 0 : -1 },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal($event)
        }
      }
    },
    [
      _c("div", {
        staticClass: "overlay",
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.closeModal($event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "outer" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c("img", { attrs: { src: _vm.image.url, title: _vm.image.title } })
        ]),
        _vm._v(" "),
        _vm.image.title
          ? _c("div", { staticClass: "caption" }, [
              _vm._v(_vm._s(_vm.image.title))
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("a", {
          staticClass: "close",
          attrs: {
            href: "#",
            "aria-label": "Close Window",
            tabindex: _vm.open ? 0 : -1
          },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.closeModal($event)
            }
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }