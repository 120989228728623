var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-ticker" }, [
    _c("h2", [_vm._v("Recent Disclosures")]),
    _vm._v(" "),
    _c("ul", [
      _c("li", [
        _c(
          "a",
          {
            class: { active: _vm.type == "deals" },
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.type = "deals"
              }
            }
          },
          [_vm._v("Deals")]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            class: { active: _vm.type == "exits" },
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.type = "exits"
              }
            }
          },
          [_vm._v("Exits")]
        )
      ]),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            class: { active: _vm.type == "raises" },
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.type = "raises"
              }
            }
          },
          [_vm._v("Raises")]
        )
      ])
    ]),
    _vm._v(" "),
    _vm.type == "deals"
      ? _c(
          "div",
          { staticClass: "deals records" },
          _vm._l(_vm.deals, function(deal) {
            return _c("div", { key: deal.id, staticClass: "row" }, [
              _c("div", { staticClass: "type" }, [_vm._v(_vm._s(deal.type))]),
              _vm._v(" "),
              _c(
                "a",
                { attrs: { href: deal.pressRelease, target: "_blank" } },
                [_vm._v(_vm._s(deal.headline))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "meta" }, [
                _c("time", {
                  attrs: { datetime: deal.date },
                  domProps: { textContent: _vm._s(_vm.formatDate(deal.date)) }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "sector" }, [
                  _vm._v(_vm._s(deal.sector))
                ])
              ])
            ])
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.type == "exits"
      ? _c(
          "div",
          { staticClass: "exits records" },
          _vm._l(_vm.exits, function(exit) {
            return _c("div", { key: exit.id, staticClass: "row" }, [
              _c("div", { staticClass: "type" }, [_vm._v(_vm._s(exit.type))]),
              _vm._v(" "),
              _c(
                "a",
                { attrs: { href: exit.pressRelease, target: "_blank" } },
                [_vm._v(_vm._s(exit.headline))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "meta" }, [
                _c("time", {
                  attrs: { datetime: exit.date },
                  domProps: { textContent: _vm._s(_vm.formatDate(exit.date)) }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "sector" }, [
                  _vm._v(_vm._s(exit.sector))
                ])
              ])
            ])
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.type == "raises"
      ? _c(
          "div",
          { staticClass: "raises records" },
          _vm._l(_vm.raises, function(raise) {
            return _c("div", { key: raise.id, staticClass: "row" }, [
              _c("div", { staticClass: "type" }, [_vm._v(_vm._s(raise.fund))]),
              _vm._v(" "),
              _c(
                "a",
                { attrs: { href: raise.pressRelease, target: "_blank" } },
                [_vm._v(_vm._s(raise.headline))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "meta" }, [
                _c("time", {
                  attrs: { datetime: raise.announced },
                  domProps: {
                    textContent: _vm._s(_vm.formatDate(raise.announced))
                  }
                })
              ])
            ])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }