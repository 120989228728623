<template>
    <div class="c-lightbox"
        :class="{ active: image.url != '' }"
        :tabindex="open ? 0 : -1"
        @keydown.esc="closeModal"
        >
        <div class="overlay" v-on:click.prevent="closeModal"></div>
        <div class="outer">
            <div class="wrapper">
                <img :src="image.url" :title="image.title">
            </div>
            <div class="caption" v-if="image.title">{{image.title}}</div>
            <a href="#" class="close" aria-label="Close Window" v-on:click.prevent="closeModal" :tabindex="open ? 0 : -1"></a>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                open: false,
                previousFocus: null,
            }
        },

        props: {
            image: {
                type: Object,
                default: function () {
                    return { url: '', title: '' }
                }
            },
        },

        methods: {
            closeModal(event) {
                this.hideModal()
            },

            hideModal() {
                var links = document.querySelectorAll('body a, *[role=button], button, input, select, textarea')
                links.forEach(function(val,index) {
                    val.setAttribute('tabindex', 0)
                })

                if( document.documentElement.classList.contains('user-is-tabbing') && this.previousFocus != null ) {
                    this.previousFocus.focus()
                }
               
                document.body.classList.remove("noscroll")

                this.open = false
                this.content = ''
                this.ajaxContent = ''
                this.previousFocus = null

                this.$emit('close')
            },
        },

        watch: {
            image( newValue, oldValue ) {
                if( newValue.url != '' ) {
                    console.log('HERE')
                    if( document.documentElement.classList.contains('user-is-tabbing') ) {
                        this.previousFocus = document.activeElement;
                    }

                    var links = document.querySelectorAll('body a, *[role=button], button, input, select, textarea')
                    links.forEach(function(val,index) {
                        val.setAttribute('tabindex', -1)
                    })

                    document.body.classList.add("noscroll")
                    this.open = true

                    if( document.documentElement.classList.contains('user-is-tabbing') ) {
                        this.$refs.modalContent.focus()
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .c-lightbox {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        z-index: -1;
        transition: opacity 0.15s linear;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0,0,0,0.75);
            z-index: 10001;
        }

        &.active {
            opacity: 1;
            z-index: 10000;

            .outer {
                opacity : 1;
                transform: translateY(0px);
                transition: opacity 0.3s linear 0.3s,
                            transform 0.2s ease-in-out 0.25s;
            }
        }

        .outer {
            position: absolute;
            background: #fff;
            padding: 0px;
            max-width: 90vw;
            z-index: 10002;  
            opacity: 0;
            transform: translateY(50px);
            transition: all 0s;
        }

        .wrapper {
            position : relative;

            img {
                display: block;
                margin: 0;
                padding: 0;
                line-height: 1em;
                max-height: 85vh;
            }
        }

        .caption {
            padding: 5px;
            background-color: #142a51;
            color: #fff;
            text-align : center;
            font-size: 16px;
        }

        .close {
            position: absolute;
            right: -32px;
            top: -32px;
            width: 32px;
            height: 32px;
            opacity: 0.8;
            z-index: 100000;

            &:hover {
                opacity: 1;
            }

            &:before, &:after {
                position: absolute;
                left: 15px;
                content: '';
                height: 33px;
                width: 4px;
                background-color: #fff;
                z-index: 1000;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
</style>