<template>
    <div class="c-ticker">

        <h2>Recent Disclosures</h2>

        <ul>
            <li><a href="#" @click.prevent="type='deals'"  :class="{ active: type=='deals'}">Deals</a></li>
            <li><a href="#" @click.prevent="type='exits'"  :class="{ active: type=='exits'}">Exits</a></li>
            <li><a href="#" @click.prevent="type='raises'" :class="{ active: type=='raises'}">Raises</a></li>
        </ul>
        
        <div class="deals records" v-if="type=='deals'">
            <div v-for="deal in deals" :key="deal.id" class="row">
                <div class="type">{{deal.type}}</div>
                <a :href="deal.pressRelease" target="_blank">{{deal.headline}}</a>
                <div class="meta">
                    <time :datetime="deal.date" v-text="formatDate(deal.date)"></time>
                    <span class="sector">{{deal.sector}}</span>
                </div>
                <!-- <div>${{deal.totalSize*1000}}</div> -->
            </div>
        </div>

        <div class="exits records" v-if="type=='exits'">
            <div v-for="exit in exits" :key="exit.id" class="row">
                <div class="type">{{exit.type}}</div>
                <a :href="exit.pressRelease" target="_blank">{{exit.headline}}</a>
                <div class="meta">
                    <time :datetime="exit.date" v-text="formatDate(exit.date)"></time>
                    <span class="sector">{{exit.sector}}</span>
                </div>
            </div>
        </div>

        <div class="raises records" v-if="type=='raises'">
            <div v-for="raise in raises" :key="raise.id" class="row">
                <div class="type">{{raise.fund}}</div>
                <a :href="raise.pressRelease" target="_blank">{{raise.headline}}</a>
                <div class="meta">
                    <time :datetime="raise.announced" v-text="formatDate(raise.announced)"></time>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                deals: [],
                exits: [],
                raises: [],
                type: 'deals',
            }
        },

        async mounted () {
            const records = await fetch('/assets/temp/recent-intelligence.json').then( res => { return res.json() })

            this.deals = records.deals
            this.exits = records.exits
            this.raises = records.raises
        },

        props: {

        },

        computed: {

        },

        methods: {
            formatDate(date) {
                let options = { month: 'short', year: 'numeric', day: 'numeric' };
                let format  = new Date(date);

                return format.toLocaleDateString("en-US", options)
            }
        },   
    }
</script>

<style lang="scss">

</style>